@import "assets/styles/global";

* {
  margin:0;
  padding:0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  text-rendering: geometricPrecision;

  @include font-primary;
  font-size: 16px;
  font-weight: 400;
  color: $primary;
}

html, body, #root, .full-height {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  max-width: 100vw;

  &.no-scroll {
    height: 100%;
    overflow: hidden;
  }
}

.title {
  font-size: 32px;
  font-weight: 700;

  @include tablet-max {
    font-size: 28px;
  }

  @include phone-max {
    font-size: 16px;
  }
}

.account-settings {
  .btn-container {
    text-align: right;
  }
}

.desktop-hidden {
  @include tablet-max {
    display: none;
  }
}

.tablet-hidden {
  @include phone-max {
    display: none;
  }
}

.d-block-tablet {
  display: none;
}

@include tablet-max {
  .d-block-tablet {
    display: block;
  }
}

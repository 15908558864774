@import "../global";


.contractor-dashboard,
.distributor-dashboard,
.past-rewards,
.account-settings,
.products-list {
  padding: 48px 48px 48px 61px;

  @include tablet-max {
    padding: 40px 40px 67px 40px;
  }

  @include phone-max {
    padding: 26px 24px;
  }
}

.line-graph-container {
  background-color: #0F4C92;
  width: 100%;
  border-radius: 20px;
  border-bottom: 4px solid rgba(255, 255, 255, 0.10);
  border-left: 4px solid rgba(255, 255, 255, 0.10);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 345px;
  padding: 32px;
  position: relative;

  .time-selection-container {
    position: absolute;
    top: 32px;
    left: 32px;
    display: flex;
    z-index: 9;

    @include phone-max {
      display: none;
    }

    .button {
      height: 55px;
      padding: 16px 32px;
      border-radius: 100px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;

      &.active {
        border: 1px solid #fff;
        background-color: #0F4C92;
      }

      @include tablet-max {
        padding: 16px 24px;
      }
    }
  }

  .totals-container {
    position: absolute;
    top: 32px;
    right: 32px;

    @at-root .past-rewards .totals-container {
      right: unset;
      left: 32px;
    }

    .earned-text {
      font-size: 26px;
      font-weight: 700;
      color: #FFF;
      text-align: right;

      @at-root  .past-rewards .totals-container .earned-text {
        text-align: left;
      }
    }

    .earned-total {
      font-size: 64px;
      font-weight: 700;
      color: #FFF;
      text-align: right;
    }

    .total-purchase {
      font-size: 16px;
      font-weight: 300;
      opacity: 0.5;
      color: #FFF;
      text-align: right;
    }
  }

  .dashboard-graph {
    max-height: 280px;
    opacity: 0;
    position: absolute;
    top: 32px;
    left: 32px;
    transition: 0.5s 0s ease-in-out;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding-right: 64px;

    &.active {
      opacity: 1;
    }
  }
}

.contractor-dashboard {
  > .time-selection-container {
    display: none;
    width: 100%;
    background-color: #0F4C92;
    grid-template-columns: 50% 50%;
    border-radius: 100px;
    height: 45px;
    padding: 6px;
    margin-top: 12px;

    @include phone-max {
      display: grid;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: rgba(#fff, 0.3);

      &.active {
        background-color: #fff;
        border-radius: 100px;
        color: #0F4C92;
      }
    }
  }
}

.main-container {
  .grid-50 {
    grid-column-gap: 32px;
    grid-template-columns: calc(50% - 16px) calc(50% - 16px);

    @include tablet-max {
      grid-template-columns: 100%;
    }
  }
}

.card-container {
  padding-top: 32px;

  @include tablet-max {
    padding-top: 40px;
  }

  @include phone-max {
    padding-top: 32px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;

    &.account-settings-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;


    }

    .title {
      color: #26272B;
      font-size: 28px;
      font-weight: 700;

      @include phone-max {
        font-size: 16px;
      }
    }

    .view-more {
      color: #1570EF;
      cursor: pointer;
      font-weight: 500;
      font-size: 20px;

      @include phone-max {
        font-size: 14px;
      }
    }
  }

  .card-body {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);

    .row {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-bottom: 1px solid #D0D5DD;

      &:last-child {
        border: none;
      }

      .top,
      .bottom {
        display: flex;
        justify-content: space-between;
      }

      .top {
        .left {
          font-size: 20px;
          font-weight: 700;
          color: #26272B;
          display: flex;
          align-items: center;

          @include phone-max {
            font-size: 16px;
          }
        }

        .description {
          color: #667085;
          font-size: 18px;
          max-width: 220px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          margin-left: 12px;
        }

        .right {
          color: #26272B;
          font-size: 20px;
          font-weight: 600;

          @include phone-max {
            font-size: 16px;
          }
        }
      }

      .bottom {
        padding-top: 8px;

        .left,
        .pending {
          color: #667085;
          font-size: 18px;

          @include phone-max {
            font-size: 14px;
          }
        }

        .dot {
          padding: 0 12px;
          color: #667085;
        }

        .accepted {
          color: #16B364;
        }

        .right {
          .box {
            border-radius: 5px;
            background: #F4F4F5;
            padding: 0 6px;
            font-size: 14px;
            color: #667085;

            @include phone-max {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@include tablet-max {
  .account-settings {
    .card-container {
      padding-top: 0;
      margin-bottom: 32px;

      .btn {
        width: 100%;
      }
    }
  }
}

@include phone-max {
  .contractor-dashboard {
    .card-container:last-child {
      margin-bottom: 10px;
    }
  }

  .account-settings {
    .card-container {
      .card-header {
        .title {
          font-size: 24px;
        }
      }

      .card-title {
        font-size: 20px;
      }
    }
  }
}

.products-list,
.distributor-dashboard {
  .products-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet-max {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 12px;
    }

    .product-count {
      border-radius: 5px;
      background: #F4F4F5;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      padding: 9px 18px;
      color: #667085;
      margin-left: 12px;

      @include phone-max {
        padding: 3px 8px;
        font-size: 12px;
      }
    }

    .status-btn-container {
      @include tablet-max {
        width: 100%;
        display: flex;
        background-color: #0F4C92;
        border-radius: 100px;
        padding: 6px;
      }

      .status {
        background-color: transparent;
        border: none;
        border-radius: 100px;
        width: 120px;
        height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: rgba(#111, 0.5);
        cursor: pointer;

        &.active {
          background-color: #0F4C92;
          color: #FFFFFF;
        }

        @include tablet-max {
          flex: 1;
          color: rgba(#fff, 0.3);
          height: 60px;

          &.active {
            background-color: #FFFFFF;
            color: #0F4C92;
          }
        }

        @include phone-max {
          height: 45px;
        }
      }
    }
  }

  .pagination {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .page-number {
      font-size: 20px;
      font-weight: 400;
      width: 35px;
      height: 36px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include phone-max {
        font-size: 14px;
        width: 28px;
        height: 28px;
      }

      &:nth-child(2) {
        margin-left: 19px;

        @include phone-max {
          margin-left: 12px;
        }
      }

      &:nth-last-child(2) {
        margin-right: 19px;

        @include phone-max {
          margin-right: 12px;
        }
      }

      &.active {
        color: #FFFFFF;
        background-color: #0F4C92;
      }
    }
  }

  .card-container {
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    padding-top: 0;
    margin-top: 12px;

    .card {
      padding: 24px;
      display: block;
      border-bottom: 1px solid #EEEFF2;

      @include phone-max {
        padding: 12px;
      }

      .top {
        display: flex;
        justify-content: space-between;

        .card-title {
          color: #1D2939;
          font-weight: 700;
          font-size: 20px;

          @include phone-max {
            font-size: 16px;
            display: flex;
          }

          .description {
            font-size: 18px;
            font-weight: 400;
            color: #667085;

            @include phone-max {
              white-space: nowrap;
              display: block;
              text-overflow: ellipsis;
              max-width: 170px;
              width: 100%;
              overflow: hidden;
              font-size: 14px;
            }
          }
        }

        .card-total {
          color: #1D2939;
          font-weight: 600;
          font-size: 20px;

          @include phone-max {
            font-size: 16px;
          }
        }
      }

      .bottom {
        padding-top: 12px;

        .date {
          color: #667085;
          font-size: 18px;

          @include phone-max {
            font-size: 14px;
          }
        }

        .dot {
          color: #667085;
          font-size: 18px;
          padding: 0 12px;

          @include phone-max {
            font-size: 14px;
          }
        }

        .accepted {
          color: #16B364;
          font-size: 18px;

          @include phone-max {
            font-size: 14px;
          }
        }

        .pending {
          color: #667085;
          font-size: 18px;

          @include phone-max {
            font-size: 14px;
          }
        }
      }
    }

    .view-more {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;

      @include tablet-max {
        display: none;
      }

      a {
        color: #1570EF;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
      }
    }
  }

  .mobile-view-more {
    display: none;

    a {
      color: #1570EF;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.4px;

      @include phone-max {
        font-size: 14px;
      }
    }
  }

  .contractor-name-input {
    padding: 18px 24px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #D0D5DD;
    margin: 12px 0;
    font-size: 18px;
    font-weight: 400;

    @include phone-max {
      margin: 0;
    }

    &::placeholder {
      @include font-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      color: rgba(#667085, 0.5);
    }

    @include phone-max {
      padding: 12px 24px;
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
      }
    }
  }

  .right,
  .upload-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    max-height: calc(100vh - 101px);
    height: 100%;
  }

  .upload-form-content {
    border: 1px dashed #98A2B3;
    border-radius: 20px;
    padding: 48px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    background-color: #f8f9fd;
    position: relative;

    .search-results-container {
      border-radius: 10px;
      position: absolute;
      top: -2px;
      width: calc(100% + 2px);
      overflow: hidden;
      backdrop-filter: blur(20px);
      z-index: 2;

      .search-result {
        border-bottom: 1px solid #D0D5DD;
        background: #FFF;
        padding: 18px 24px;
        cursor: pointer;

        color: #667085;
        @include font-primary;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;

        &:hover {
          background: darken(#FFF, 0.8);
        }
      }
    }

    &.highlight {
      background-color: darken(#F8F9FD, 5%);
    }

    .upload-icon {
      margin-bottom: 24px;
      width: 32px;
      height: 32px;
    }

    .upload-text {
      @include font-primary;
      font-size: 18px;
      font-weight: 400;
      color: #667085;
      line-height: 30px;

      label {
        color: #1570ef;
        cursor: pointer;
      }
    }

    .extensions {
      font-size: 14px;
      font-weight: 400;
      color: #98A2B3;
      margin-top: 8px;
    }
  }

  .upload-form-inactive-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.hidden {
      display: none;
    }
  }

  .upload-form-active-content {
    width: 220px;
    z-index: 1;

    &.hidden {
      display: none;
    }
  }

  .progress-ring-container {
    position: relative;
    width: 220px;
  }

  .progress-value-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .progress,
    .progress-value {
      font-size: 48px;
      font-weight: 700;
    }
  }

  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .uploading-file-description {
    font-size: 18px;
    font-weight: 400;
    color: #667085;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    .cancel-file-upload {
      cursor: pointer;
    }
  }

  .invoice-file-name {
    font-size: 18px;
    font-weight: 400;
    color: #667085;
  }

  .invoice-mobile-upload,
  .invoice-form-submit {
    font-size: 18px;
    font-weight: 400;
    padding: 18px 24px;
    border-radius: 10px;
    background-color: #0F4C92;
    color: #FFFFFF;
    border: none;
    margin-top: 12px;
    cursor: pointer;
    line-height: 30px;

    &:disabled {
      background-color: grey;
      cursor: default;
    }
  }

  .invoice-mobile-upload {
    display: none;
  }

  .upload-form-active-content-mobile {
    background-color: #F2F4F7;
    padding: 12px 18px;
    border-radius: 10px;

    &.hidden {
      display: none;
    }

    &.active {
      display: block;
    }

    @include phone-max {
      margin-top: 12px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        padding-bottom: 12px;
      }
    }

    .file,
    .filename {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #667085;

      @include phone-max {
        font-size: 12px;
      }
    }

    .progress-line-container {
      height: 4px;
      border-radius: 200px;
      flex: 1;
      background-color: #D9D9D9;
      position: relative;

      .progress-line {
        background-color: #0F4C92;
        width: 0;
        position: absolute;
        height: 100%;
        border-radius: 200px;
      }
    }

    .progress-number-container {
      padding-left: 12px;
      color: #667085;

      .number {
        color: #667085;
      }
    }
  }

  @include tablet-max {
    .left {
      order: 2;

      .mobile-view-more {
        display: inline-block;
      }

      .title-container {
        display: flex;
        justify-content: space-between;
      }
    }

    .right {
      order: 1;

      .title {
        display: none;
      }

      .upload-form-content {
        visibility: hidden;
        padding: 0;

        .upload-form-active-content,
        .upload-form-inactive-content {
          display: none;
        }

        .search-results-container {
          visibility: visible;
        }
      }

      .invoice-form-submit {
        display: none;
      }

      .invoice-mobile-upload {
        font-size: 18px;
        font-weight: 400;
        padding: 18px 24px;
        border-radius: 10px;
        background-color: #0F4C92;
        color: #FFFFFF;
        border: none;
        margin-top: 12px;
        cursor: pointer;
        line-height: 30px;
        display: inline-block;

        @include phone-max {
          padding: 12px 24px;
          font-size: 16px;
        }
      }

      .upload-form {
        padding-bottom: 32px;
      }
    }
  }

  @include phone-max {
    &.grid-50 {
      grid-template-rows: min-content 1fr;
    }

    .invoice-form-submit {
      padding: 12px 24px;
    }

    .right {
      .upload-form {
        padding-bottom: 12px;
      }
    }
  }
}

.grid-item.item-id {
  position: relative;
}

.product-number-search-container {
  position: absolute;
  left: 0;
  top: 38px;
  background-color: #FFF;
  z-index: 9;
  overflow-y: scroll;
  width: max-content;
  padding: 12px;
  padding-top: 20px;
  max-height: 300px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #D0D5DD;
  border-right: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;

  .product-number-search-item {
    cursor: pointer;
    padding: 8px;
  }
}

.past-rewards {
  .card-container {
    .card-body {
      .row {
        border-bottom: 1px solid #EEEFF2;

        .top {
          .card-title {
            color: #1D2939;
            font-weight: 700;
            font-size: 20px;
          }

          .right {
            color: #16B364;
          }
        }

        .bottom {
          .right {
            .box {
              padding: 4px 12px;
            }
          }
        }
      }
    }
  }
}

.invoice-card {
  cursor: pointer;
}

.account-settings {
  .card-title {
    font-size: 20px;
    font-weight: 700;
    color: #1d2939;
    padding-bottom: 24px;
    display: grid;
    grid-template-columns: 50% 50%;

    @include phone-max {
      grid-template-columns: 100%;
      grid-row-gap: 20px;
    }
  }

  .sales-rep-name {
    font-size: 16px;
    padding-left: 12px;

    @include phone-max {
      display: flex;
      flex-direction: column;
    }
  }

  .sales-rep-secondary {
    margin-left: 24px;

    @include phone-max {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  .sales-rep-label {
    font-weight: bold;
  }

  .form-container {
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    @include tablet-max {
      grid-template-columns: 100%;
    }

    .distributor {
      font-size: 18px;
      font-weight: 700;
      color: #667085;
    }

    .form-group {
      label,
      input,
      select {
        font-size: 18px;
        font-weight: 700;
        color: #667085;
      }

      label {
        display: block;
        padding-bottom: 8px;
      }

      select,
      input {
        width: 100%;
        padding: 12px 18px;
        border-radius: 15px;
        border: 1px solid #D0D5DD;
        background-color: #FFF;

        &:read-only {
          background: #f6f6f6;
        }
      }
    }
  }
}

.invoice-back-arrow {
  display: none;
  cursor: pointer;
}

.link {
  color: #0F4C92;
}

@include tablet-max {
  .invoice-modal {
    background-color: transparent;

    .modal-card {
      border-radius: 0;

      .card-header {
        .invoice-back-arrow {
          display: block;
          margin-right: 4px;
        }

        .actions {
          display: none;
        }
      }

      .card-body {
        .body-header {
          .name {
            font-size: 32px;
          }
        }
      }
    }
  }
}

@include phone-max {
  .invoice-modal {
    .modal-card {
      .card-body {
        .body-header {
          .name {
            font-size: 24px;
          }

          .date {
            input {
              font-size: 14px;
              padding: 14px 24px;
            }
          }
        }

        .product-grid {
          .grid-container {
            grid-row-gap: 12px;
          }

          .grid-item {
            label.mobile {
              font-size: 14px;
            }

            input[type=text] {
              font-size: 14px;
              padding: 14px 24px;
            }
          }
        }
      }
    }
  }
}

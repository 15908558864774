@import "../../assets/styles/global";

.sidebar {
  width: 100%;
  padding: 40px;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-max {
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    top: 93px;
    transform: translateX(-100%);
    z-index: 999;

    &.active {
      display: flex;
      animation: slide-in 0.5s forwards;
    }

    .logo {
      display: none;
    }
  }

  .logo {
    max-width: 203px;
    width: 100%;
    height: 53px;
    cursor: pointer;
    align-self: center;
  }
}

.nav-items {
  list-style: none;

  li:first-child {
    margin-top: 120px;

    @include tablet-max {
      margin-top: 0;
    }
  }

  .nav-item {
    max-width: 282px;
    height: 76px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 24px;
    color: #667085;
    font-size: 22px;
    @include font-primary;
    font-weight: 500;
    opacity: 0.6;

    .icon {
      margin-right: 6px;
    }

    .icon path {
      fill: #667085;
    }

    &.active {
      background-color: rgba(242, 242, 246, 0.60);
      color: #0F4C92;
      opacity: 1;

      .icon path {
        fill: #0F4C92;
      }
    }

    @include tablet-max {
      height: 90px;
      color: #0F4C92;
      font-size: 32px;
      opacity: 1;
      max-width: 100%;

      &.active {
        background-color: transparent;
      }

      .icon {
        width: 40px;
        height: 40px;
      }

      .icon path {
        fill: #0F4C92;
      }
    }
  }
}

.grid-50 {
  display: grid;
  grid-template-columns: 50% 50%;

  @include tablet-max {
    grid-template-columns: 100%;
  }
}

.main-container {
  display: grid;
  grid-template-columns: 350px 1fr;
  background-color: #F8F9FD;
  position: relative;
  height: auto;
  min-height: 100%;

  @include tablet-max {
    grid-template-columns: 100%;
    background-color: #FFFFFF;
  }
}

.invoice-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 99;

  @include tablet-max {
    top: 77px;
    transform: translateX(-100%);
  }

  &.active {
    display: flex;

    @include tablet-max {
      animation: slide-in 0.5s forwards;
    }
  }

  &.inactive {

    @include tablet-max {
      animation: slide-out 0.5s forwards;
    }
  }

  @keyframes slide-in {
    100% { transform: translateX(0%); }
  }

  @keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
  }

  .modal-card {
    border-radius: 20px;
    background-color: #FFFFFF;
    overflow: hidden;
    box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.6), 0px 4px 8px -2px rgba(16, 24, 40, 0.7);
    max-height: 96vh;
    overflow-y: scroll;

    @include tablet-max {
      width: 100%;
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 80px;
      border-radius: 0;
      max-height: 100vh;
    }

    .card-header {
      padding: 24px 24px 24px 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #D0D5DD;

      @include phone-max {
        border-bottom: none;
        padding: 24px 8px 24px 8px;
      }

      @include small-phone-max {
        flex-direction: column;
      }

      .file-info {
        display: flex;
        align-items: center;

        @include phone-max {
          .invoice-icon {
            width: 40px;
            height: 40px;
          }

          .invoice-back-arrow {
            width: 24px;
            height: 24px;
          }
        }

        @include small-phone-max {
          width: 100%;
          padding-bottom: 24px;
        }

        p {
          font-size: 32px;
          font-weight: 700;
          letter-spacing: -0.64px;
          margin-left: 8px;

          @include phone-max {
            font-size: 18px;
          }

          @include small-phone-max {
            text-align: right;
          }
        }

        a {
          font-size: 18px;
          font-weight: 400;
          color: #1570ef;
          margin-left: 8px;

          @include phone-max {
            font-size: 12px;
          }
        }
      }

      .actions {
        display: flex;

        @include small-phone-max {
          width: 100%;
          justify-content: space-between;
        }

        .btn {
          padding: 8px 24px;
          width: auto;
          margin-right: 20px;

          &.btn-danger {
            background: #da4f49;
            color: white;
            margin-right: 50px;
          }
        }

        .invoice-modal-close {
          width: 48px;
          height: 48px;
          cursor: pointer;
          border-radius: 8px;
          border: none;
          background-color: rgba(242, 242, 246, 0.60);;
        }
      }
    }

    .card-body {
      padding: 24px 48px;

      @include phone-max {
        padding: 18px;
      }

      .body-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include phone-max {
          flex-direction: column;
          align-items: flex-start;
        }

        .name {
          font-size: 40px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -0.8px;
          color: #0F4C92;

          @include phone-max {
            padding-bottom: 28px;
          }
        }

        .date {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          @include phone-max {
            width: 100%;
          }

          input {
            padding: 18px 24px;
            border-radius: 10px;
            border: 1px solid #d0d5dd;
            font-size: 18px;
            font-weight: 400;
            color: #667085;
            width: 150px;
          }

          .stacked-input {
            display: flex;
            flex-direction: column;
            margin-right: 15px;
            input {
              padding: 12px 24px;
            }
          }

          .invoice-number-input {
            margin-right: 20px;
            max-width: 300px;
            width: 100%;

            @include  phone-max {
              max-width: 100%;
              flex: 1;
            }
          }
        }
      }

      .product-grid {
        padding-top: 32px;
        padding-bottom: 36px;

        .grid-container {
          display: grid;
          grid-template-columns: 141px 141px 411px 120px 111px 159px 243px 32px;
          padding-top: 16px;
          grid-row-gap: 16px;

          @include tablet-max {
            padding-top: 0;
            padding-bottom: 40px;
            margin-bottom: 40px;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid #D0D5DD;

            &:first-child {
              display: none;
            }

            &:last-child {
              border-bottom: none;
              margin-bottom: 0;
              padding-bottom: 14px;
            }
          }
        }

        .grid-header {
          background-color: #F2F4F7;
          text-transform: uppercase;
          height: 47px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.56px;
          color: #98A2B3;
          margin-bottom: 8px;

          &:first-child {
            padding-left: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          &.last-column {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          @include tablet-max {
            display: none;
          }
        }

        .grid-item {
          &.product-id {
            padding-left: 10px;
          }

          &.invalid {
            input {
              background-color: yellow;
            }
          }

          padding-right: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.checkbox-container {
            justify-content: flex-start;
          }

          label.mobile {
            display: none;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            color: #667085;
            text-transform: uppercase;

            @include tablet-max {
              display: block;
            }
          }

          @include tablet-max {
            flex-direction: column;
            align-items: flex-start;

            &.product-id {
              padding-left: 0;
            }

            &.qty {
              grid-column: 2/3;
              grid-row: 1;
              padding-right: 0;
            }

            &.description {
              padding-right: 0;
            }

            &.price {

            }

            &.total {
              padding-right: 0;
            }

            &.checkbox-container {
              flex-direction: row;
              align-items: center;
              margin-top: 20px;
              padding-bottom: 60px;

              label {
                padding-left: 12px;
                white-space: nowrap;
              }
            }

            &.trash {
              align-items: flex-end;
              padding-right: 0;
              margin-top: 20px;
            }
          }

          input[type=text] {
            width: 100%;
            padding: 10px 12px;
            font-size: 18px;
            font-weight: 400;
            color: #667085;
            border: 1px solid #D0D5DD;
            border-radius: 10px;

            @include tablet-max {
              padding: 18px 24px;
            }
          }

          input[type=checkbox] {
            display: none;
          }

          .stylish-checkbox {
            width: 42px;
            min-width: 42px;
            height: 42px;
            border: 1px solid #98A2B3;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &.readonly {
              cursor: default;
            }

            .check {
              display: none;
            }
          }

          &.checked {
            .stylish-checkbox {
              background-color: #D0D5DD;
              border: none;

              .check {
                display: block;
              }
            }
          }

          .delete-item {
            cursor: pointer;
          }
        }

        .total {
          input {
            background-color: #F9FAFB;
          }
        }
      }

      .add-product {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #0F4C92;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include tablet-max {
          padding-bottom: 16px;
        }

        @include phone-max {
          font-size: 14px;
        }

        img {
          margin-left: 10px;
          width: 24px;
          height: 24px;

          @include phone-max {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .card-footer {
      padding: 32px 48px;
      background-color: #0F4C92;
      display: flex;
      justify-content: space-between;

      > div {
        color: #FFFFFF;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: -0.64px;

        @include phone-max {
          font-size: 24px;
        }
      }
    }
  }

  &.alert-modal {
    z-index: 100;
    .modal-card {
      max-width: 400px;
      width: 100%;
      overflow-y: hidden;

      .card-header {
        .file-info {
          p {
            margin-left: 0;
          }
        }
      }

      .card-body {
        &.alert-message {
          padding: 32px 48px;
        }
      }

      .invoice-modal-action {
        font-size: 16px;
        font-weight: 400;
        padding: 12px 15px;
        border-radius: 10px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        line-height: 20px;
        &.action-confirm {
          background-color: #0F4C92;
          margin-right: auto;
        }
        &.action-deny {
          background-color: grey;
          float: right;
        }
      }
    }
  }
}

.pt-4 {
  padding-top: 40px;
}

@import "../global";

.home-slider-container {
  max-height: calc(100vh - 80px);
  position: relative;
  padding: 10% 15%;

  @include tablet-max {
    padding: 0;
  }
}

.home-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 74px;
  background-color: #ffffff;

  @include tablet-max {
    padding-top: 53px;
  }

  @include phone-max {
    padding-top: 33px;
  }

  .form-logo {
    margin-bottom: 86px;

    @include tablet-max {
      display: none;
    }
  }

  .signin-container {
    max-width: 510px;
    width: 100%;

    @include tablet-max {
      max-width: 100%;
      padding: 0 40px;
    }

    @include phone-max {
      padding: 0 25px;
    }

    .title {
      margin-bottom: 32px;
      text-align: center;
      font-size: 32px;

      @include phone-max {
        font-size: 24px;
      }
    }

    .submit {
      margin-top: 8px;

      @include tablet-max {
        padding: 24px 0;
      }

      @include phone-max {
        padding: 12px 0;
      }
    }
  }

  .sign-up {
    color: #939094;
    margin-top: 24px;

    @include tablet-max {
      margin-top: 170px;
      padding-bottom: 42px;
    }

    @include phone-max {
      margin-top: 100px;
      font-size: 14px;
      padding-bottom: 32px;
    }

    a {
      color: #0F4C92;

      @include phone-max {
        font-size: 14px;
      }
    }
  }
}

@include tablet-max {
  .site-header {
    height: 93px;
  }

  .d-block-tablet.logo {
    width: 204px;
    height: 54px;
  }
}

@include phone-max {
  .site-header {
    height: 78px;
  }

  .d-block-tablet.logo {
    width: 160px;
    height: 42px;
  }
}

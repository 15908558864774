.btn {
  padding: 16px 0;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 240px;

  &-primary {
    background-color: #0F4C92;
    color: #ffffff;
  }

  &-secondary {

  }

  &-wide {
    width: 100%;
  }
}

@import "../../assets/styles/global";

.slick-slide {
  position: relative;

  .slide {
    position: relative;
  }

  img {
    width: 100%;
    max-height: calc(100vh - 80px);
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 77.64%), #000 50% / cover no-repeat;
    z-index: 1;
    opacity: 0.2;
  }

  .caption {
    position: absolute;
    bottom: 0;
    padding: 64px;
    width: 100%;
    background-color: transparent;
    z-index: 2;

    .slide-title {
      font-size: 64px;
      @include font-secondary;
      color: #FFF;

      @include tablet-max {
        font-size: 40px;
        font-weight: 400;
      }

      @include phone-max {
        font-size: 24px;
      }
    }

    p {
      color: #FFF;
      font-size: 24px;

      @include tablet-max {
        font-size: 16px;
      }

      @include phone-max {
        font-size: 14px;
      }
    }

    @include tablet-max {
      max-width: 450px;
      padding: 0 0 32px 40px;
    }

    @include phone-max {
      padding: 24px;
    }
  }
}

.slide-arrow-container {
  position: absolute;
  top: calc(10% + 24px);
  right: calc(15% + 24px);
  z-index: 3;
  display: flex;

  @include tablet-max {
    top: 32px;
    right: 40px;
  }

  .slide-arrow-nav {
    border: 1.5px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    width: 76px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left {
      margin-right: 24px;

      @include phone-max {
        margin-right: 12px;
      }
    }

    &.right {
      transform: rotate(180deg);
    }

    @include tablet-max {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    @include phone-max {
      width: 24px;
      height: 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.form {
  .input-container {
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #C9C5CA;
      padding: 16px 0;
      margin-bottom: 40px;

      &::placeholder {
        font-size: 16px;
        color: #939094;
      }
    }

    &.password {
      padding-bottom: 40px;

      input {
        margin-bottom: 6px;
      }

      p {
        text-align: right;
      }
    }
  }
}

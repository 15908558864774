@import "../../assets/styles/global";

.site-header {
  height: 79px;
  padding: 24px 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: #ffffff;

  .desktop-logo {
    height: 31px;
  }

  @include tablet-max {
    .desktop-logo {
      display: none;
    }
  }
}

.mobile-header {
  justify-content: space-between;
  align-items: center;
  padding: 12px 36px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.10);
  position: relative;
  z-index: 9;
  display: none;

  @include tablet-max {
    display: flex;
  }

  .hamburger-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    position: relative;

    .hamburger {
      opacity: 1;
      transition: 0.3s 0s ease-in-out;
    }

    .mobile-menu-close {
      position: absolute;
      top: 24px;
      left: 12px;
      width: 24px;
      height: 24px;
      opacity: 0;
      transition: 0.3s 0s ease-in-out;
    }

    &.active {
      .hamburger {
        opacity: 0;
      }

      .mobile-menu-close {
        opacity: 1;
      }
    }
  }
}
